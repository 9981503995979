import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { UrlsService } from '../../services/urls.service';
import { User } from '../../service/user';
import { Eleve } from '../../service/eleve';
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  nomuser: String="";
  prenomuser: String="";
  typeuservv: String="";
  login: String="";
  avatarvv: String="";
  avat: String="";
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  nbmessage=0;
  avatarpp: String="assets/images/etablissement/User-Profile.png";
  public uservv: User;
  public allmessagetout: any;
  constructor(@Inject(DOCUMENT) private document: any, private router: Router, private authService: AuthenticationService,
              private authFackservice: AuthfakeauthenticationService,
              public languageService: LanguageService,
              public translate: TranslateService,
              public _cookiesService: CookieService,
              private http: HttpClient,private url:UrlsService
              ) {
  }

  listLang = [
    { text: 'Français', flag: 'assets/images/etablissement/french.jpg', lang: 'fr' },
    { text: 'Anglais', flag: 'assets/images/etablissement/us.jpg', lang: 'en' },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  recuptype_user(id:String) {
    return this.http
    .get<User[]>(this.url.detailuser+id)
  }




  allnotif(){

    this.router.navigate(['Allnotification']);
  }


  /*recuptype_all_notification() {
    return this.http
    .get<Eleve[]>(this.url.allmessages)
  }*/
 /* refreshnotif(){




       this.allmessagetout = JSON.parse(localStorage.getItem("key"));

       this.nbmessage=this.allmessagetout.length;

  }*/
  
  detailnotification( index:string){

     this.http.post<any>(this.url.addmessagelu,
       JSON.stringify({  
         idmessage: index,
         iduser:   localStorage.getItem('iduser'),
        })).subscribe(datamessage => {
 
        }); 
 
     
     let lien = 'Detailnotification/'+ index;
     this.router.navigateByUrl(lien);
   }
  ngOnInit() {

   /* this.allmessagetout = JSON.parse(localStorage.getItem("key"));
    this.nbmessage=this.allmessagetout.length;*/

 


    this.openMobileMenu = false;
    this.element = document.documentElement;

  //  this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === 'fr');
    this.countryName = val.map(element => element.text);
    this.flagvalue = val.map(element => element.flag);


    /*if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/etablissement/french.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }*/



    this.avat=localStorage.getItem('nomuser').substring(0,1);
    this.nomuser=localStorage.getItem('nomuser');
    this.prenomuser=localStorage.getItem('prenomuser');
    this.typeuservv=localStorage.getItem('typeuser');
    this.login=localStorage.getItem('login');
    this.avatarvv=localStorage.getItem('avatarvv');


   /* this.recuptype_user(this.Iduser).subscribe(
      data => {
        this.uservv = JSON.parse(JSON.stringify(data)); 
        if(this.uservv?.profilpicture){
          this.avatarpp= this.uservv?.profilpicture;
        }else{

        }
        
         
      },
        );*/

  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.authFackservice.logout();
    this.router.navigate(['/account/login']);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
}
