<header id="page-topbar">
  <div class="navbar-header" >
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box" style=" background-color: rgb(3, 10, 73);">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="17">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/etablissement/LOBOBON.png" alt="" height="22">
          </span>
          <span class="logo-lg">

            <a  href="javascript:void(0);" >
              <!-- <img src="assets/images/etablissement/LOBOBON.png" alt="" height="35">-->
              <span style="color: #dedef1; font-size: 30px;">INTSCHOOL</span>
           </a>


        
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
      <form class="app-search d-none d-xl-block">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | translate}}">
          <span class="bx bx-search-alt"></span>
        </div>
      </form>






    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown"
          aria-haspopup="true" ngbDropdownToggle aria-expanded="false">
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-search-dropdown">
          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> <span
            class="ms-1">{{countryName}}</span>
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
          <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
              class="align-middle">{{item.text}}</span>
          </a>
          <!-- item-->
        </div>
      </div>



      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>










      <div class="dropdown d-inline-block" ngbDropdown>
        <button  type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          ngbDropdownToggle>
          <i class="bx bx-bell bx-tada"></i>
          <span class="badge bg-danger rounded-pill">{{nbmessage}}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">NOTIFICATIONS </h6>
              </div>
             
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 230px;">

            <!--<a href="javascript: void(0);"  class="text-reset notification-item"  *ngFor="let data of allmessagetout">
              <div class="media" (click)="detailnotification(data.id)">


                
              

             




              <div class="avatar-xs me-3">
                <a     href="javascript: void(0);"> <span 
                    class="avatar-title rounded-circle bg-soft font-size-18" 
                    ngClass="text-warning'">
                    {{data.titre.charAt(0)}}
                </span></a>
            </div>






                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{data.titre}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{data.description}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>
                      {{ data.datecreation | date: 'dd/MM/yyyy, à HH:mm' }} </p>
                  </div>
                </div>
              </div>
            </a>-->



          </ngx-simplebar>
          <!--<div class="p-2 border-top d-grid">
            <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)" (click)="allnotif()">
              <i class="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">Voir toutes les notifications</span>
            </a>
          </div>-->
        </div>
      </div>














      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item noti-icon right-bar-toggle" ngbDropdownToggle id="page-header-user-dropdown">
          <div class="avatar-xs"  *ngIf="avatarvv==='null' else loggedOut">
            <span class="avatar-title rounded-circle">
                
                {{avat}}
            </span>
          </div>
          <ng-template #loggedOut>
            <img class="rounded-circle header-profile-user" [src]="avatarvv" alt="A">
            </ng-template>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" routerLink="/contacts/profile"><i class="bx bx-user font-size-16 align-middle me-1"></i>Profile</a>
          <a class="dropdown-item d-block" href="javascript: void(0);"><i class="bx bx-wrench font-size-16 align-middle me-1"></i>Paramètre utilisateur</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>Déconnexion</a>
        </div>
      </div>













      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">



          <span class="d-none d-xl-inline-block ms-1">{{nomuser}} {{prenomuser}}</span>
        
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" routerLink="/contacts/profile"><i class="bx bx-user font-size-16 align-middle me-1"></i>Profile</a>
          <a class="dropdown-item d-block" href="javascript: void(0);"><i class="bx bx-wrench font-size-16 align-middle me-1"></i>Paramètre utilisateur</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>Déconnexion</a>
        </div>
      </div>

       <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="toggleRightSidebar()">
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div>

    </div>
  </div>
</header>